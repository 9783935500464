
const aliases = {
  home: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.home',
  },
  login: {
    page: false,
    name: 'login',
  },
  history: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.balance-history'
  },
  bonuses: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.profile.staff'
  },
  availableBonuses: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.profile.staff'
  },
  promo: {
    page: true,
    name: 'app.root.layer1.layer2.promo.list',
  },
  promotions: {
    page: true,
    name: 'app.root.layer1.layer2.promo.list',
  },
  registration: {
    page: false,
    name: 'login',
  },
  paynplay: {
    page: false,
    name: 'login',
  },
  deposit: {
    page: false,
    name: 'cashbox',
    private: true,
  },
  withdraw: {
    page: false,
    name: 'cashbox',
    private: true,
    content: { action: "withdrawal" }
  },
  chat: {
    page: false,
    name: 'chat',
  },
  realGame: {
    page: true,
    name: 'app.root.real',
  },
  initialLimitPage: {
    page: true,
    name: 'app.root.deposit-page',
  },
  // initialTimeLimitPage: {
  //     page: true,
  //     name: 'app.root.time-page'
  // },
  missedData: {
    page: true,
    name: 'app.root.missedData',
  },
  notFound: {
    page: true,
    name: 'app.root.layer1.layer2.not-found',
  },
  depositLimits: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.selfcontrol',
  },
  verification: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.profile.verification',
  },
  tournaments: {
    page: true,
    name: 'app.root.layer1.layer2.tournaments'
  },
  dropwins: {
    page: true,
    name: 'app.root.layer1.layer2.tournament',
    params: {
      name: 'drops-wins',
    },
  },
  sport: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
  },
  bet: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
  },
  virtualSport: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.virtual'
  },
  live: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.layer5.catalog.games',
    params: {
      name: 'live-casino',
    },
  },
  games: {
    page: true,
    name: "app.root.layer1.layer2.layer3.layer5.catalog.games",
    params: {
      name: 'all-games',
    },
  },
  profile: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.profile.staff'
  },


  horseracing: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home'
  },
  football: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
    params: {
      sportids: '66',
    },
  },
  volleyball: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
    params: {
      sportids: '69',
    },
  },
  tennis: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
    params: {
      sportids: '68',
    },
  },
  "table-tennis": {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
    params: {
      sportids: '77',
    },
  },
  basketball: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
    params: {
      sportids: '67',
    },
  },
  "ice-hockey": {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home',
    params: {
      sportids: '70',
    },
  },
  esports: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home'
  },
  formula1: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home'
  },
  mma: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home'
  },
  cricket: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home'
  },
  "american-football": {
    page: true,
    name: 'app.root.l1.sportpage.home'
  },
  baseball: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home'
  },
  efootball: {
    page: true,
    name: 'app.root.layer1.layer2.sportpage.home'
  },

  cash_crab: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.layer5.catalog.claw_games',
    params: {name: 'cash_crab'}
  },

  // no_action: {
  //
  // }
};


export {aliases};

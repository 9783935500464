import { $_PGW } from "./pgw.mjs";

var $_CONFIG = $_CONFIG || {};

$_CONFIG.scripts = $_CONFIG.scripts || []; // масив скриптів, які будуть додані в дом
$_CONFIG.gsapVersion = '3';
$_CONFIG.gsap3Plugins = ['MorphSVGPlugin.min.js'];

$_CONFIG.jurisdiction = 'sweden';

$_CONFIG.domain = 'https://yoyocasino-se.devv2.space'; // домен апі (рекомендовано залишати пустим)
$_CONFIG.siteDomain = 'https://yoyocasino.se';

$_CONFIG.cdn = '/joxi'; // домен сервера cdn (поки один на всі проекти)

$_CONFIG.siteName = 'yoyo';
//todo pixel
$_CONFIG.mid = 21; // id пікселя для інкам. ВАЖЛИВО! В кожного сайта своє

$_CONFIG.redirectToNotFound = false;
$_CONFIG.lottielib = true;

$_CONFIG.symplify = true;
$_CONFIG.jquery = false;

$_CONFIG.googleAnalytics = {
  appId: 'UA-90104580-2',
  trackAs: 'yoyoSE',
};

$_CONFIG.p161 = {
  view: '3161456',
  registration: '3161463',
  firstDeposit: '3161460',
  deposit: '3161459',
};

$_CONFIG.pgw = $_PGW;

$_CONFIG.feature = {
  initCookieScriptViaGtag: true,
}

$_CONFIG.places = {

  icons: {
    footer1: `'footer_pay'`,
    footer2: `'footer_icons'`,
  },
};

export { $_CONFIG };
